
import React from 'react';


import MainPageComp from './MainPageComp';
import { BrowserRouter, Route, Routes } from "react-router-dom";


import TagPageComp from './TagPageComp';
import ContentCategory from './ContentCategory';
import Topic from './Topic';
import About from './About';
import Privacy from './Privacy';
import Donotsell from './Donotsell';
import NotFound from '../Component/NotFound';
import Contact from './Contact';
import {SearchList} from './SearchList';
import Sitemap from './Sitemap';


const MainRoutes = () => {
  return (

    <>
    
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPageComp />}></Route>
      <Route path='/tag/:subcat' element={<TagPageComp />} />
      <Route path='/:cat_slug/:post_name' element={<Topic/>} />



      <Route path='/topic/:cat_slug' element={<ContentCategory />}></Route>
      <Route path='/topic/:cat_slug/:subcat' element={<ContentCategory />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path='/privacy-policy' element={<Privacy />}></Route>
      <Route path='/opt-out-form' element={<Donotsell />}></Route>
      <Route path="/contact-us" element={<Contact />}></Route>
      <Route exact path="/search/:cat/:searchVal" element={<SearchList/>} />
      <Route path="/sitemap" element={<Sitemap />}></Route>


      <Route path="*" element={<NotFound />}></Route>
      </Routes>
      </BrowserRouter>
    
    </>

  );
}

export default MainRoutes;