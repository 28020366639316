import React from 'react'
import { useState,useEffect } from 'react'
import axios from 'axios';
// import LazyLoad from 'react-lazyload';
import Homepage from './HomePage'
import { GuestComp } from './GuestComp'
import { QuickComp } from './QuickComp'
import PodcastComp from './PodcastComp'
import { API_ROOT,webPath } from '../apiconfig';

import { Helmet } from 'react-helmet';



function MainPageComp() {


  const [advertisementData, setAdvertisementData] = useState([]);

useEffect(() => {
  const fetchAdvertisementData = async () => {
    try {
      const response = await axios.get(`${API_ROOT}/api/advertisement/get_active`);
      setAdvertisementData(response.data);

    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  fetchAdvertisementData();
}, []);

const pageTitle = "Talk Fintech - News | Editorial insights | Fintech Updates";

  return (
    <div style={{overflow:"hidden"}} className='no-gutters'>
 
 <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="A Peer Knowledge Resource Expert inputs on challenges, triumphs ; innovative solutions from corporate Movers ; Shakers in global Leadership." />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta property="og:type" content="PostDetails" />
        <meta name="image" property="og:image" content="https://talkfintech.com/static/media/TalkFintech.0d98293385406252f383.webp" />
        <meta name="description" property="og:description" content="A Peer Knowledge Resource Expert inputs on challenges, triumphs &amp; innovative solutions from corporate Movers &amp; Shakers in global Leadership." />
        <meta property="og:url" content="https://talkfintech.com/" />
        <meta property="og:image:width" content="844" />
        <meta property="og:image:height" content="172" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://x.com/talk_fintech"></meta>
        <link rel="canonical" href="talkfintech.com/" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content="A Peer Knowledge Resource Expert inputs on challenges, triumphs ; innovative solutions from corporate Movers ; Shakers in global Leadership." />
        <meta name="twitter:image" content="https://talkfintech.com/static/media/TalkFintech.0d98293385406252f383.webp" />
      </Helmet>


    <Homepage/>
    

    

    <GuestComp/>




    <QuickComp/>
  


    <PodcastComp/>
  
  

    <div className="container container-max">
        <div className="row mt-2 spaceincontentbottm">
          <div className="col-md-12 mb-2 borderB">
            <div >
            {advertisementData && advertisementData.length > 0 && (
             <a href={`${advertisementData[2].dest_url}`}> <img
                style={{ width: "100%", height:"auto" }}
                src={`${webPath}${advertisementData[2].banner_img}`}
                alt={advertisementData[2].banner_name}  aria-label={advertisementData[2].banner_name}
                loading="lazy" width="640" height="360" /> </a>
            )}
            </div>
          </div>
        </div>
      </div>
    
    
    
    </div>
  )
}

export default MainPageComp

