// import React, { lazy ,useCallback} from "react";
// import Navbar from "react-bootstrap/Navbar";
// import TalkFintech from "../Images/TalkFintech.webp";

// const Sidebar = lazy(() => import("./Sidebar"));
// const SearchSubComp = lazy(() => import("./SearchSubComp"));

// const Logo = () => (
//   <a href="/">
//     <img
//       className="MainLogo" 
//       src={TalkFintech}
//       alt="Logo"
//       width="180"
//       height="80"

//     />
//   </a>
// );

// const MainNavComp = () => {
//   const renderLogo = useCallback(() => <Logo />, []);
//   return (
//     <div className="fixed-top nav-title"  style={{ overflow: "hidden" }}>
//       <Navbar collapseOnSelect expand="md" className="container justify-content-between">
//         <div className="impNav">
//           <Sidebar />
//           <div className="imgDiv" style={{ overflow: "hidden" }}>
//           <Navbar.Brand>
//           {renderLogo()}
//         </Navbar.Brand>
//           </div>
//         </div>
//         <div className="Desktopresponsive">
//           <SearchSubComp />
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default React.memo(MainNavComp);



import React, { lazy ,useCallback} from "react";
import Navbar from "react-bootstrap/Navbar";
import TalkFintech from "../Images/TalkFintech.webp";

const Sidebar = lazy(() => import("./Sidebar"));
const SearchSubComp = lazy(() => import("./SearchSubComp"));

const Logo = () => (
  <a href="/">
    <img
      className="MainLogo" 
      src={TalkFintech}
      alt="Logo"
      width="180"
      height="80"
      loading="lazy"
    />
  </a>
);

const MainNavComp = () => {
  const renderLogo = useCallback(() => <Logo />, []);
  return (
    <div className="fixed-top nav-title">
      <Navbar collapseOnSelect expand="md" className="container justify-content-between">
        <div className="impNav">
          <Sidebar />
          <div className="imgDiv">
          <Navbar.Brand>
          {renderLogo()}
        </Navbar.Brand>
          </div>
        </div>
        <div className="Desktopresponsive">
          <SearchSubComp />
        </div>
      </Navbar>
    </div>
  );
};

export default React.memo(MainNavComp);
